// This file contains the overrides for the REGISTER BANNER
// It is imported in the _variant-mz.scss file

.fp-register-banner {
  &__content {
    @include space(padding, all, $spacer * 6);
    background: $color-primary;
    @media screen and (max-width: $mobile) {
      @include padding($spacer * 6 $spacer * 4); }
  }

  &__button {
    color: $color-primary;
  }

  &__logo-small {
    @include space(margin, vertical, 0);
    @include space(margin, horizontal, auto);
    @include space(margin, bottom, $spacer * 6);
    background-image: url($publication-banner-logo-small-path);
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    display: block;
    height: 22px;
    padding: 3%;
    width: 60px;
  }
}


