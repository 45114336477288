// This file contains variant for the NEWSLETTER BANNER styles
// This file is imported into the _variant-mz.scss file

.fp-newsletter-banner {
  &__logo-container {
    &::before {
      background: $color-primary;
    }
  }

  &__logo {
    span {
      @include size(220px, 22px);
    }
  }

  &__content {
    background: $color-primary;
  }

  &__button {
    background: $white;
    color: $color-primary;
  }
}
