.fp-category-section__main-article {
  @media screen and (max-width: $mobile) {
    .fp-article__image {
      margin-left: calc(-100vw / 2 + (100vw - 32px) / 2);
      margin-right: calc(-100vw / 2 + (100vw - 32px) / 2);
      max-width: 100vw;
      width: 100vw;
    }
  }
}
