.fp-related-articles {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);
  }
}

.fp-most-popular-section {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);
  }
}

.fp-section-topic {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);
  }
}

.fp-topic-list ~ #nativendo-nativendo-infeed {
  @include size(100%, null);
  @include margins($spacer * 6 0);
}

.general-page {
  #nativendo-nativendo-infeed {
    @include size(100%, null);
    @include margins($spacer * 6 0);
  }
}
