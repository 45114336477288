.fp-paid-article {
  &__article {
    &::before {
      background-color: $color-primary;
      background-image: url('../images/mz-plus.svg');
      background-size: 70%;
    }
  }

  &__section-title {
    &::before {
      background: $color-primary;
      background-image: url('../images/mz-plus.svg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: 70%;
    }
  }
}

.nt-paid {
  &::before {
    background: #0096dc;
    background-image: url('../images/nt-plus.svg');
    background-position: center;
    background-repeat: no-repeat;
  }
}

.fp-paid-article-indicator-r {
  &__article {
    &::after {
      @include space(margin, left, $spacer * 4);
      background-color: $color-primary;
      background-image: url('../images/mz-plus.svg');
      background-size: 70%;
    }
  }
}
